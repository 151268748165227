export type Locale = "de";

export const i18n = {
  // This is the list of languages your application supports
  supportedLngs: ["de"],
  // This is the language you want to use in case
  // if the user language is not in the supportedLngs
  fallbackLng: "de",
  // The default namespace of i18next is "translation", but you can customize it here
  defaultNS: "common",
  // Disabling suspense is recommended
  react: { useSuspense: false },
};

export default i18n;